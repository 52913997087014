import { useEffect, useState } from "react";
import "./App.css";
import { useSearchParams } from "react-router-dom";
import { useCookies } from "react-cookie";

function App() {
  const constants = {
    SSO_MBP_REFERER: "SSO_MBP_REFERER",
    API_MBP_SID: "API_MBP_SID",
  };

  const routes = {
    USER_AUTH_GOOGLE_LOGIN: "user/auth/google/login",
  };

  const [searchParams] = useSearchParams();

  const [cookies, setCookie, removeCookie] = useCookies([
    constants.SSO_MBP_REFERER,
    constants.API_MBP_SID,
  ]);

  // console.log({ cookies });

  useEffect(() => {
    const refererParam = searchParams.get("referer");
    if (refererParam) {
      setCookie(constants.SSO_MBP_REFERER, refererParam);
    }
    if (cookies.API_MBP_SID && cookies.SSO_MBP_REFERER) {
      const cookiesCopy = cookies;
      const handleRedirect = () => {
        removeCookie(constants.API_MBP_SID);
        removeCookie(constants.SSO_MBP_REFERER);
        window.location.assign(
          `${process.env.REACT_APP_ADMIN_BASE_URL}?SID=${encodeURIComponent(
            cookiesCopy.API_MBP_SID.trim()
          )}`
        );
      };
      handleRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="flex flex-col justify-center h-screen py-16 bg-gray-200">
      <div className="flex flex-col items-center justify-between h-full ">
        {/* SSO Box */}
        <div className="flex flex-col items-center p-8 mt-8 bg-white shadow-2xl rounded-xl w-96">
          <img
            src="/images/logo.png"
            loading="lazy"
            className="w-full mb-8"
            alt="mbp logo"
          />
          <label className="mb-4 text-4xl font-light text-gray-700">
            Single <span className="font-bold">Sign-On</span>
          </label>
          {/* <input
            type="text"
            className="w-full h-12 px-4 mb-4 text-lg bg-gray-100 rounded-lg focus:ring-teal-600"
            placeholder="Email"
            disabled={true}
            autoComplete="off"
          />
          <input
            type="password"
            className="w-full h-12 px-4 mb-4 text-lg bg-gray-100 rounded-lg focus:ring-teal-600"
            placeholder="Password"
            disabled={true}
            autoComplete="off"
          />
          <button
            className="w-full h-12 mb-4 font-semibold uppercase transition bg-gray-300 rounded-lg text-gray-50 "
            disabled
          >
            Login
          </button>
          <p className="mb-4 text-right">Forgot password</p>
          <label className="mb-4 text-gray-800">or</label> */}
          <a
            href={`${process.env.REACT_APP_API_BASE_URL}/${routes.USER_AUTH_GOOGLE_LOGIN}`}
            onClick={() => setIsLoading(true)}
            className="w-full h-12 mb-4 font-semibold text-gray-200 uppercase transition bg-[#8B0000] rounded-lg hover:bg-[#8B0000] shadow-lg"
          >
            <div className="relative flex flex-col items-center justify-center h-full space-x-4">
              {!isLoading ? (
                <>
                  <img
                    src="https://tailus.io/sources/blocks/social/preview/images/google.svg"
                    className="absolute w-5 left-8"
                    alt="google logo"
                  />
                  <span className="block text-sm font-semibold tracking-wide text-gray-200 transition duration-300 w-max group-hover:text-blue-600 sm:text-base">
                    Login with Google
                  </span>
                </>
              ) : (
                <div className="flex flex-col items-center justify-center h-full space-y-2 to-red-[#8B0000]">
                  <div className="flex items-center justify-center rounded-full h-8 w-8 bg-gradient-to-tr from-gray-200 to-red-[#8B0000] animate-spin">
                    <div className="to-red-[#8B0000] rounded-full h-3 w-3"></div>
                  </div>
                </div>
              )}
            </div>
          </a>
        </div>
        {/* Terms and Disclaimers */}
        <div className="mt-8 space-y-4 text-center text-gray-600 w-96">
          <p className="text-xs">
            By proceeding, you agree to our{" "}
            <button onClick={() => {}} className="underline">
              Terms of Use
            </button>{" "}
            and confirm you have read our{" "}
            <button onClick={() => {}} className="underline">
              Privacy and Cookie Statement
            </button>
            .
          </p>
          <p className="text-xs">
            This site is protected by reCAPTCHA and the{" "}
            <button onClick={() => {}} className="underline">
              Google Privacy Policy
            </button>{" "}
            and{" "}
            <button onClick={() => {}} className="underline">
              Terms of Service
            </button>{" "}
            apply.
          </p>
        </div>
      </div>
    </div>
  );
}

export default App;
